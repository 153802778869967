import { DOMAIN, SECURE, HOSTNAME } from './cookies-config.js';

const REGEX_ENVIRONMENT_DEV = /(localhost|web\.dev|review-classic)/;
const IS_ENVIRONMENT_DEV = REGEX_ENVIRONMENT_DEV.test(HOSTNAME);

export const LOCAL_STORAGE_KEYS = {
	DEVICE_ID: 'device_id',
	AOFL_TOKEN: 'aofl_token',
	SOURCE_INFO: 'source_info',
	DEVICE_CODE: 'device_code',
	EXPERIMENTS: 'client-experiments'
};

export const AOFL_PRODUCT_NAMES = {
	ABCMOUSE: 'abcmouse',
	ABCMOUSE_2_0: 'abcmouse_v2'
};

export const AOFL_LEGACY_ROLES = {
	abcmousev2_legacy_user: 'abcmousev2_legacy_user',
	abcmousev2_legacy_teacher: 'abcmousev2_legacy_teacher',
	abcmousev2_legacy_assessment: 'abcmousev2_legacy_assessment'
};

export const EVENT_NAMES = {
	CLIENT_VALIDATION: 'marketing.client_form_validation',
	EXPERIMENT_ASSIGNMENT: 'marketing.rotation_assignment',
	DEBUG: 'marketing.client_debug',
	MIGRATION_REDIRECT: 'marketing.migration_user_redirect',
	MIGRATION_LANDING: 'marketing.migration_user_landing',
	MIGRATION_ROTATION: 'marketing.migration_rotation_assignment',
	CAMPAIGN_CLICK: 'marketing.campaign_click',
	LINK_CLICK: 'marketing.page_link_click',
	PAGE_LOAD: 'marketing.page_load',
	PIXEL_FIRE: 'marketing.pixel_fire',
	ROTATION_ASSIGN: 'marketing.rotation_assignment',
	VIDEO_PROGRESS: 'marketing.video_watched'
};

export const PLATFORMS = {
	DEFAULT: 'default_platform'
};

export const ACTION_SOURCES = {
	EMAIL_SPECIAL_OFFER_UPGRADE: 'email_special_offer_upgrade',
	REG_PATH: 'regpath'
};

export const AOFL_PRODUCT_UUIDS = {
	ABCMOUSE: '2d6e4e3e-3711-499b-93e3-1919aac44a27',
	ABCMOUSE_2_0: 'b2892456-6e59-11ee-b962-0242ac120002',
	HOMESCHOOL: '4be90567-6d1a-43ab-afe6-51faa8d847eb',
	ABCMOUSE_ENGLISH_ES: 'c19722f3-9659-472e-9ccc-1aefa2c44803',
	ADVENTURE_ACADEMY: '4d984c26-1a72-11e7-93ae-92361f002671'
};

export const GTM_EVENT_NAMES = {
	CONVERSION: 'conversion',
	PROSPECT_REG_SUBMITTED: 'prospect-reg',
	LOGIN: 'login'
};

export const PAYMENT_TYPES = {
	CREDIT_CARD: 'paymentech',
	PAYPAL: 'paypal',
	APPLE_PAY: 'apple_pay'
};

export const SUBSCRIPTION_TYPES = {
	NEW_SUBSCRIPTION: 'firstTimeSubscription',
	// covers both reactivation and buying additional products.
	EXISTING_SUBSCRIPTION: 'existingSubscription',
	ITEM_PURCHASE: 'itemPurchase'
};

export const TRANSACTION_TYPE = {
	PREPROCESS: 'preprocess',
	PROCESS: 'process'
};

export const APP_CALLS = {
	ADJUST_EVENT: 'event://adjustEvent',
	CLOSE_APP: 'application://close',
	GO_BACK: 'location://goBack?showParentGate=false',
	LOG_EVENT: 'event://logEvent',
	LOGIN: 'session://login',
	LOGOUT: 'application://logout',
	NAVIGATE: 'location://navigate',
	PURCHASE: 'transaction://purchase',
	RESTORE: 'transaction://restore',
	SESSION_GET: 'session://get',
	UPGRADE: 'transaction://upgrade'
};

export const APP_CALL_CALLBACKS = {
	ADJUST_EVENT: 'adjustCallback',
	FIREBASE_EVENT: 'firebaseCallback',
	LOGIN: 'loginCallback',
	PURCHASE: 'purchaseCallback',
	RESTORE: 'restoreCallback',
	UPGRADE: 'upgradeCallback'
};

export const THIRD_PARTY_EVENTS = {
	SUBSCRIBE: 'Subscribe',
	INITIATE_CHECKOUT: 'InitiateCheckout'
};

export const APP_STORES = {
	ITUNES: 'iTunes',
	GOOGLE_PLAY: 'GooglePlay',
	AMAZON: 'Amazon',
	APPLE_PAY_MERCHANT_ID: 'merchant.abcmouse.com'
};

export const UsernameTypes = {
	EMAIL: 'email'
};

export const OUTBOUND_LINKS = {
	IPV6: IS_ENVIRONMENT_DEV ? 'https://deq4mt61w2hci.cloudfront.net/trk' : 'https://ipv6.abcmouse.com/trk'
};

export const OSU_REG_STEPS_ENUMERATE = {
	COMPLETE: 'REGPATH_LAST_STEP',
	DOWNLOAD_APP: 'REGPATH_DOWNLOAD_APP',
	REACTIVATE: 'REGPATH_REACTIVATION',
	UPGRADE: 'REGPATH_UPGRADE',
	UPGRADE_SAMSUNG: 'REGPATH_UPGRADE_SAMSUNG',
	ASSESSMENT: 'REGPATH_ASSESSMENT',
	CONFIRMATION: 'REGPATH_CONFIRMATION',
	AA_UPSELL_OFFER: 'REGPATH_AA_OFFER',
	WORKBOOK: 'REGPATH_WORKBOOK'
};

export const OSU_REGPATH_PAGES = {
	[OSU_REG_STEPS_ENUMERATE.AA_UPSELL_OFFER]: '/abc/aa-offer/',
	[OSU_REG_STEPS_ENUMERATE.ASSESSMENT]: '/abc/assessment-purchase/',
	[OSU_REG_STEPS_ENUMERATE.CONFIRMATION]: '/abc/confirmation/',
	[OSU_REG_STEPS_ENUMERATE.COMPLETE]: '/abc/inside-pw',
	[OSU_REG_STEPS_ENUMERATE.DOWNLOAD_APP]: '/abc/download-app/',
	[OSU_REG_STEPS_ENUMERATE.UPGRADE]: '/abc/upgrade/',
	[OSU_REG_STEPS_ENUMERATE.WORKBOOK]: '/abc/starter-pack-workbook-offer/',
	[OSU_REG_STEPS_ENUMERATE.REACTIVATE]: '/abc/reactivation/'
};

export const OUTBOUND_PATH = {
	LINK_TO_APP: 'https://abcmouse.go.link/auth?adj_t=1kmlo5gl&adj_label=token&token='
};

export const PAGES = {
	INTERNAL: {
		HOME: '/abc',
		HOME_AOFLJS: '/abc/?arm=9100',
		HOME_ES: '/abc/es/',
		AA_OFFER: '/abc/aa-offer',
		SUBSCRIPTION: '/abc/subscription',
		SUBSCRIPTION_OFFER: (/** @type {string} */ offer) => `/abc/subscription/offer/?${offer}`,
		REACTIVATION: '/abc/reactivation',
		REDEEM: '/abc/redeem',
		UPGRADE: '/abc/upgrade',
		PROSPECT_REGISTER: '/abc/prospect-register',
		DOWNLOAD_APP: '/abc/download-app',
		INSIDE_PAYWALL: '/abc/inside-pw',
		GIVE_A_GIFT: '/abc/purchase-gift/',
		LOGIN: '/abc/login',
		LG_TV: '/abc/lg-tv',
		ASSESSMENT_PURCHASE: '/abc/assessment-purchase',
		LOGOUT: '/abc/logout',
		OFFER_EXPIRED: '/abc/expired',
		RESET_PASSWORD: '/abc/reset-password',
		LEGACY: {
			ABOUT_US: '/about-us',
			ABOUT_US_ADVISORY_BOARD: '/about-us#advisory_board',
			COOKIE_POLICY: '/cookie-policy',
			CUSTOMER_SUPPORT: '/customer-support',
			EDUCATIONAL_GAMES: '/educational-games',
			FAQS: '/faqs',
			GIFT: '/gift',
			GIFT_CARDS: '/code',
			HOMESCHOOL: '/homeschool',
			KINDERGARTEN: '/kindergarten',
			LIBRARIES: '/libraries',
			LOGIN: '/login',
			MATH: '/math',
			PHONICS: '/phonics',
			PRE_KINDERGARTEN: '/pre-kindergarten',
			PRESCHOOL: '/preschool',
			PRIVACY_POLICY: '/privacy',
			READING: '/reading',
			TEACHERS: '/teachers',
			NEWSLETTER: '/newsletter',
			RESULTS_AND_REFERENCES: '/results-and-references',
			TANDC: '/tandc'
		}
	},
	EXTERNAL: {
		ABCMOUSE: 'https://www.abcmouse.com',
		ABCMOUSE_SEO_GAMES_PAGE: 'https://www.abcmouse.com/games',
		ACTIVITIES: 'https://www.abcmouse.com/learn/category-activities',
		AOFL_ABCMOUSE_COOKIE_POLICY: 'https://www.ageoflearning.com/abc-cookie-policy/',
		AOFL_BRAND_ABCM: 'https://www.ageoflearning.com/brand/abcmouse_landing_v1',
		AOFL_FOR_SCHOOLS: 'https://www.ageoflearning.com/schools/',
		AOFL_DEVICES: 'https://www.ageoflearning.com/devices/aofl_devices_en.pdf',
		ACCESSIBILITY: 'https://www.ageoflearning.com/accessibility',
		CUSTOMER_SUPPORT: 'https://support.abcmouse.com',
		CUSTOMER_SUPPORT_CONTACT_US: 'https://support.abcmouse.com/hc/en-us/articles/360047572854',
		PRIVACY_PDF: 'https://img.abcmouse.com/pdf/privacy.pdf',
		PRIVACY_PORTAL:
			'https://privacyportal-cdn.onetrust.com/dsarwebform/a2692794-047c-4c18-b3bf-60d783ebc844/8205df50-001c-4e16-b5f9-d4e945bc612e.html',
		IPV6: 'https://ipv6.abcmouse.com/trk',
		LEARN: 'https://www.abcmouse.com/learn',

		ALLABOUT_DNT: 'http://www.allaboutdnt.org',
		CCPA_METRICS: 'https://www.ageoflearning.com/ccpa/',
		CUSTOMER_SUPPORT_FORGOT_EMAIL:
			'https://support.abcmouse.com/hc/en-us/articles/4413252218903-Account-Access',
		CUSTOMER_SUPPORT_FORGOT_PASSWORD:
			'https://support.abcmouse.com/hc/en-us/articles/4413252218903?forgotpassword=true',
		CUSTOMER_SUPPORT_FAQS:
			'https://support.abcmouse.com/hc/en-us/articles/360053552753-What-devices-should-I-use-with-ABCmouse-',
		AOFL_RESEARCH: 'https://www.ageoflearning.com/research/',
		GOOGLE_UCB_LEARN_MORE: 'https://support.google.com/googleplay/answer/11174377',
		HOMESCHOOLPLUS: 'https://www.homeschoolplus.com',
		HOMESCHOOLPLUS_SUBSCRIPTION: 'https://www.homeschoolplus.com/subscription/',
		SUNMAID_WALMART: 'https://www.walmart.com/cp/sunmaidbacktoschool/3335011',
		SUNMAID_SIGHT_WORDS_PDF: 'https://img.abcmouse.com/pdf/abcmouse_sunmaid_sight_words_rgb_06.pdf',
		SUNMAID_MATH_WITH_RAISINS_PDF: 'https://img.abcmouse.com/pdf/sunmaid_book_letter_12.pdf',
		SUNMAID_COLORING_PAGES_PDF: 'https://img.abcmouse.com/pdf/sunmaid_alphabet_coloring_pages.pdf',
		SUNMAID_LETTER_TRACING_PDF: 'https://img.abcmouse.com/pdf/abcmouse_letter_tracing.pdf',
		KID_SAFE: 'https://www.kidsafeseal.com/certifiedproducts/abcmouse.html',
		PRIVACY_DE: 'https://www.ageoflearning.com/abc-privacy-german',
		PRIVACY_SE: 'https://www.ageoflearning.com/abc-privacy-swedish',
		TANDC_DE: 'https://www.ageoflearning.com/abc-tandc-german',
		TANDC_SE: 'https://www.ageoflearning.com/abc-tandc-swedish',
		PRIVACY_HUB: 'https://www.ageoflearning.com/privacy-policies/',
		PRIVACY_POLICY: 'https://www.ageoflearning.com/abc-privacy-current/',
		PRINTABLES: 'https://www.abcmouse.com/learn/category-printables-worksheets',
		PRIVACY_ES: 'https://www.ageoflearning.com/abc-privacy-current-es/',
		TERMS_CONDITIONS: 'https://www.ageoflearning.com/abc-tandc-current/',
		TERMS_CONDITIONS_ES: 'https://www.ageoflearning.com/abc-tandc-current-es/',
		TIKTOK: 'https://www.tiktok.com/@abcmouse',
		FACEBOOK: 'https://www.abcmouse.com/outsideurl?url=facebook',
		FACEBOOK_PAGE: 'https://www.facebook.com/ABCmouse/',
		FACEBOOK_GROUP: 'https://www.facebook.com/groups/abcmouse.orientation/about/',
		INSTAGRAM: 'https://www.instagram.com/abcmouse/',
		PINTEREST: 'https://www.abcmouse.com/outsideurl?url=pinterest',
		PINTEREST_PAGE: 'https://www.pinterest.com/abcmouse/',
		CAREERS: 'https://www.ageoflearning.com/careers/',
		AOFL: 'https://www.ageoflearning.com',
		MEDIA: 'https://www.ageoflearning.com/media/'
	}
};

export const QUERY_PARAM_KEYS = {
	EMAIL_CLICK: '_0c83',
	SOURCE_TAG: 'src_tag',
	PRODUCT_TYPE: 'b_term',
	GOTO: 'to',
	TV_DEVICE_CODE: 'tvdc',
	CLIENT_TYPE: 'ct',
	TOKEN: 'token',
	CJ_EVENT: 'cjevent'
};

export const QUERY_PARAM_VALUES = {
	B_TERM: {
		MONTHYL: 'monthly',
		ANNUAL: 'annual'
	}
};

export const CAMPAIGN_PIXEL_TYPES = {
	CONVERSION: 'conversion',
	LANDING: 'landing',
	PROSPECT_REGISTER: 'sign-up'
};

export const REG_PATH_KEYS = {
	DEFAULT_SUBSCRIBER: 'defaultSubscriber',
	DEFAULT_RESUBSCRIBER: 'defaultResubscriber',
	NO_AA_SUBSCRIBER: 'noAASubscriber',
	IN_APP_SUBSCRIBER: 'inAppSubscriber',
	IN_APP_RESUBSCRIBE: 'inAppResubscriber',
	GOOGLE_IN_APP_SUBSCRIBER: 'googleInAppSubscriber',
	IN_APP_UCB_SUBSCRIBER: 'inAppUcbSubscriber',
	IN_APP_UCB_RESUBSCRIBER: 'inAppUcbResubscriber',
	SUBSCRIBER_NO_PATH: 'subscriberNoPath',
	RESUBSCRIBE_NO_PATH: 'resubscriberNoPath',
	DEFAULT_SUBSCRIBER_PLUS: 'defaultSubscriberPlus',
	DEFAULT_RESUBSCRIBER_PLUS: 'defaultResubscriberPlus',
};

export const REG_PATH_STEPS = {
	ASSESSMENT: 'REGPATH_ASSESSMENT',
	DOWNLOAD_APP: 'REGPATH_DOWNLOAD_APP',
	AA_OFFER: 'REGPATH_AA_OFFER',
	COMPLETE: 'REGPATH_COMPLETE',
	SUBSCRIBED: 'REGPATH_SUBSCRIBED', // Step at which the user has subscribed.
	UPGRADE: 'REGPATH_UPGRADE',
	RESET: 'DEFAULT_STEP' // Step to reset a reg-path: should not be included in any reg-path, used primarily for reactivation users.
};

export const SUBSCRIPTION_HASHES = {
	PAYMENT_TECH: {
		aa_offer: '78a35b75-997e-44d6-8f2d-7bb6f677d164',
		monthly_fwf: '751b26740991c3895e4d2069beedfd28', // $12.99
		monthly_fwf_1499: 'cf643269fa6bf53718640211a2c582f64e996fc696cbc994df518cd2a4b89e6b', // $14.99
		monthly_fmf: 'd4vdn3idd9kr5uoexm4xij4zhhlcc9hc', // $14.99
		annual: 'a705135a5ca0263he12632d074f7796c',
		semi_annual: 'e6d014c1363cae08b8c019b7cf5d935a',
		upgrade: '6d24d3e822431efca22d73f50d4da9fb', // $45.00
		asessment_monthly: '30a5c22b794f10a376ae452bf78fb4f2', // $3.95
		assessment_annual: '2530146ebb330f58f5d633b2b23c868c',
		assessment_semi_annual: 'b0e51d17de8e6f364e414f11719f9b4c',
		gift_one_year: 'dbee304be9df12b57fd6279c76dab1eb',
		gift_two_year: '3f141af954cb78d2387f34f3be357f95',
		monthly_reactivation: 'e91fba0ff4be204eb6c6ff64d5da9057', // $14.99
		annual_reactivation: 'cb19da4514dgb2dbc5419e18s2c72b7c', // $79.99
		annual_reactivation_upgrade: 'cd44fasa2e3e754114d36bf572d4e6c2', // $59.40
		semi_annual_reactivation: '7a4852696c02f5bf2f72b93badce0f75', // $29.99
		monthly_fwf_2_0: 'ce2f536ce33f40f5890f05b924f00047b7daf7494bea8472c7c921222f5a37da', // $14.99 - 2_0
		monthly_fmf_2_0: 'f1eab9c7f585ee6e6b45e993af095abc96cbe361dc575f5539e3aaad0bc0a6a6', // $14.99 - 2_0
		monthly_no_trial_2_0: '872652378a49578c6a4d5c2d1b4eb959b7fe04cbdf0a87a30c02d184bedca8b7', // $12.99 - 2_0
		annual_2_0: '0276c52238259b3948b86616639ffaf3fa9be4eb89c976085b98835648eb9882', // $45.00 - 2_0
		semi_annual_2_0: 'a671ba9535d019ed4ae92f2974fe665bd1fd9f06e08f67aadaf9ccff719e46ac', // $25.00 - 2_0
		monthly_reactivation_2_0: 'ed789ce4963b763b69a4ab495da0e2b4a4d6d7b92dd8acf4e9217e7b24ea57ea', // $14.99 - 2_0
		annual_reactivation_2_0: 'a008741e48cdcb14b845f9dbd200ddac7615c1ede49836a77d66c18570ed3c3b', // $79.99 - 2_0
		semi_annual_reactivation_2_0: 'ceae6f76c08bf577fd3d7f35d020592e0d2168180cfc23307a743b5e6a913d17', // $29.99 - 2_0
		annual_reactivation_upgrade_2_0: 'd2c6fd260eb98ca2c859f949f7d6901d5ad3b928426f423894e90d6d1417c643', // $59.40 - 2_0
	},
	IOS: {
		monthly_reactivation: '9e0c85b3e45f689aa3a314b095d3b5e2', // $14.99 (No. FWF)
		annual_reactivation: '8a4e4472f86b2b934b4e629bf774b39c', // $59.99
		monthly: '38b4a3e29632683ac78214592759e7c1c9ca4a18f2d18df4ce35e18cd32f1436', // product id: 1709 - $14.99
		annual: '5d90d4f04b8be5117c43ef9d347cf791f745c7b72b2ade3a0c6ea89bf5d3f805', // product id: 1706 - $45 First Year ($59.99 Annual)
		semi_annual: 'c20574e9c47493f66f5fdd7d9f401d70cbec02ea99127edb184672cc73ed05f8', // product id: - 1708 $39.99
		upgrade: 'db0bcb73506b765358426073633376da09d6d06c7427866be92b2865177212be', // product id: 1707 - $49.99
		assessment: 'c9a609d125f991492ee007cc357ff2b9' // $39.99 (Annual)
	},
	AMAZON: {
		monthly_reactivation: 'cbe475080673df42c6bdfb995b46931e', // $12.99
		annual_reactivation: 'a1430b7a89569a3d3270160cb882d995', // $59.99
		annual: '7d8a6b9c4e3f1b5d2c7e0f1a8b2d6c9f', // $49.99
		semi_annual: '3c51e00d5db8dabba4bd3f7a5d45e6cd', // $29.99
		monthly: 'bdc8247b33e3369a502e2ec85fc46312' // $14.99
	},
	ANDROID: {
		annual_iap: 'c59d36a81c3f20ff504400d872b93036', // $45 annual
		annual_resub_iap: 'dbca284350428313df5993a53a69dbfb', // $45 reactivation
		monthly_iap: '00e50f0b3505b45d5713f4498f279c1bc728d1775eba802264b44f0f6cd0db9f', // 1710 - $14.99
		monthly_no_trial_resub_iap: '75ad7b06a31b9ac4733af4ddce331da6', // $12.99 reactivation
		annual_ucb: '2e695f6b5a244b24fc2d5bb99981416c', // $45 annual
		annual_resub_ucb: '2e695f6b5a244b24fc2d5bb99981416c', // $45 reactivation
		monthly_ucb: 'f4a0a099513e52fd17d5b3e97518f30356004eda68d537aa0faa165fb7eb38d4', // 1712 - $14.99
		monthly_no_trial_resub_ucb: 'b3adcdb80453082703928d7f6b429a11', // $12.99 reactivation
		annual_upgrade_ucb: 'bd6eaf157d9983d60dd704dedcebe709', // $45 annual
		semi_annual_upgrade_ucb: '6c1dd228cea87c7ea5c3092da5064be7' // $25.00
	}
};

export const AUXILIARY_PRODUCT_TO_ROLE = {
	assessment: 'abcmouse_assessment_user'
};

export const COOKIES = {
	CONFIG: {
		secure: SECURE,
		domain: DOMAIN,
		expires: 365
	},
	CJ_CONFIG: {
		secure: SECURE,
		domain: DOMAIN,
		expires: 35
	},
	CJ_COOKIE: 'cje',
	TRACK_COOKIE: 'track',
	MIGRATION_USER_TYPE: 'migration_user_type',
	ASSESSMENT_LOGIN: 'assessmentLogin',
	GDPR: 'gdpr',
	EXPERIMENTS: 'x-aofl-experiment-info',
	LEGACY_CAMPAIGN_ID: 's_cid',
	LEGACY_CAMPAIGN_ID_TWO: 's_cid2',
	LEGACY_CAMPAIGN_ID_THREE: 'original_s_cid', // It's stupid, I know (AD). It's okay; at least it's not called dim_52, progress (PG). Perpetual migration (AD).
	LEGACY_CAMPAIGN_VARS: 's_cid_vars'
};

export const COOKIES_CONSENT = {
	ANALYTICS: 'ANALYTICS_PERFORMANCE_COOKIES',
	TARGETING: 'TARGETING_COOKIES'
};

// TODO: merge this, no reason to have a separate list.
/** @type {{[key:string]: string; }} */
export const COUNTRY_CODES_GDPR = {
	AT: 'Austria',
	AU: 'Australia',
	BE: 'Belgium',
	BG: 'Bulgaria',
	CY: 'Republic of Cyprus',
	CZ: 'Czech Republic',
	DE: 'Germany',
	DK: 'Denmark',
	EE: 'Estonia',
	ES: 'Spain',
	EU: 'European Union',
	FI: 'Finland',
	FR: 'France',
	GB: 'United Kingdom',
	GR: 'Greece',
	HU: 'Hungary',
	HR: 'Croatia',
	IE: 'Ireland',
	IT: 'Italy',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	LV: 'Latvia',
	MT: 'Malta',
	NL: 'Netherlands',
	PL: 'Poland',
	PT: 'Portugal',
	RO: 'Romania',
	SE: 'Sweden',
	SK: 'Slovakia',
	SI: 'Slovenia',
	GDPR: 'GDPR'
};

export const CURRENCY_CODE = {
	AUSTRALIAN_DOLLAR: 'AUD',
	CANADIAN_DOLLAR: 'CAD',
	CHINESE_YUAN: 'CNY',
	EURO: 'EUR',
	BRITISH_POUND: 'GBP',
	ISRAELI_SHEKEL: 'ILS',
	JAPANESE_YEN: 'JPY',
	MEXICAN_PESO: 'MXN',
	NEW_ZEALAND_DOLLAR: 'NZD',
	SWEDISH_KRONA: 'SEK',
	SINGAPORE_DOLLAR: 'SGD',
	TAIWAN_DOLLAR: 'TWD',
	US_DOLLAR: 'USD',
	VIETNAMESE_DONG: 'VND'
};

export const CURRENCY_SYMBOL = {
	AUD: '$',
	CAD: '$',
	CNY: '¥',
	EUR: '€',
	GBP: '£',
	ILS: '₪',
	JPY: '¥',
	MXN: '$',
	NZD: '$',
	SEK: 'kr',
	SGD: '$',
	TWD: 'NT$',
	USD: '$',
	VND: '₫'
};
