import { ExperimentQualifier, BrowserAdapter } from '@mkt-client/experiment-qualifier';
import * as Sentry from '@sentry/sveltekit';
import { AnalyticsService } from '$lib/services/analytics';
import { app } from '$lib/stores/app/store';
import { get } from 'svelte/store';
import { LOCAL_STORAGE_KEYS } from '$lib/utils/constants';
import { config } from 'chai';

/** @constant {string} Key used to fetch experiments configuration */
const EXPERIMENTS_CONFIG_KEY = 'experiments.json';

/**
 * Fetches the experiments configuration from the server
 * @returns {Promise<object>} The experiments configuration object
 * @property {Array} experiments - Array of experiment configurations
 * @property {object} experiment_arms - Map of experiment arms keyed by experiment ID
 */
const getConfig = async () => {
	const fallbackConfig = {
		experiments: [],
		experiment_arms: {}
	};

	try {
		const configResp = await fetch(`${window.location.origin}/${EXPERIMENTS_CONFIG_KEY}`);
		const config = await configResp.json();
		return config;
	} catch (error) {
		Sentry.captureException(error, {
			extra: {
				action: 'Error: Experiments Config Failed To Fetch'
			}
		});
		return fallbackConfig;
	}
};

/**
 * Builds the request context for experiment evaluation
 * @returns {object} The request context object containing user detection and analytics data
 * @property {object} userDetection - User detection information
 * @property {string} src - Analytics source tag
 * @property {object} params - URL search parameters
 */
const getRequestContext = () => {
	// TODO: clean this up later, consolidate with infra-utils
	const appMeta = get(app).meta;
	const paramsObj = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(paramsObj.entries());
	return {
		...appMeta,
		src: AnalyticsService.getSourceTag(),
		params
	};
};

/**
 * Qualifies experiments based on configuration and context, storing results in localStorage
 * @returns {Promise<void>}
 * @throws {Error} If experiment qualification fails
 */
export const qualifyExperiments = async () => {
	const config = await getConfig();

	try {
		const requestContext = getRequestContext();
		const browserAdapter = new BrowserAdapter();
		const experimentQualifier = new ExperimentQualifier(config, browserAdapter);

		const result = experimentQualifier.selectExperimentArms();
		delete result.disallowed;
		delete result.reason;
		delete result.first_assignment;

		const experiments = ExperimentQualifier.evaluateConditionFromHeader(result, requestContext);

		// Store only the experiment assignments
		const experimentAssignments = {};
		for (const [expId, exp] of Object.entries(experiments)) {
			if (exp && typeof exp === 'object' && 'id' in exp) {
				experimentAssignments[expId] = {
					id: exp.id,
					condition_expression: exp.condition_expression
				};
			}
		}

		localStorage.setItem(LOCAL_STORAGE_KEYS.EXPERIMENTS, JSON.stringify(experimentAssignments));
	} catch (error) {
		Sentry.captureException(error, {
			extra: {
				action: 'Error: Failed To Get Experiments'
			}
		});
	}
};

/**
 * Checks if a specific experiment arm is currently active
 * @param {string} armId - The ID of the experiment arm to check
 * @returns {boolean} - True if the arm is active, false otherwise
 * @throws {Error} If localStorage access or JSON parsing fails (caught internally)
 * @example
 * // Check if a specific arm is active
 * if (isExperimentArmActive('8892')) {
 *   // Show variant content
 * } else {
 *   // Show control content
 * }
 */
export const isExperimentArmActive = (armId) => {
	try {
		const experimentsJson = localStorage.getItem(LOCAL_STORAGE_KEYS.EXPERIMENTS);
		if (!experimentsJson) return false;

		const experiments = JSON.parse(experimentsJson);
		// Check all experiments to see if any have the specified arm ID
		const isActive = Object.values(experiments).some((experiment) => {
			return experiment?.id === armId;
		});
		if (isActive) {
			// TODO: fire rotation assignment event from logger when done
			AnalyticsService.trackExperimentAsssignment({
				rotationId: armId
			});
		}

		return isActive;
	} catch (error) {
		Sentry.captureException(error, {
			extra: {
				action: 'Error: Failed To Check Experiment Arm',
				armId
			}
		});
		return false;
	}
};

/**
 * Change the experiments configuration based on the experiment arm ID
 * @param {string} fromArmId - The ID of the experiment arm switch from
 * @param {string} toArmId - The ID of the experiment arm to switch to
 * @param {{force: boolean}} options - Options for the change
 * @returns {Promise<boolean>} - True if the arm is active, false otherwise
 * @throws {Error} If localStorage access or JSON parsing fails (caught internally)
 * @example

 */
export const changeExperiment = async (fromArmId, toArmId, { force = true }) => {
	try {
		const experimentsJson = localStorage.getItem(LOCAL_STORAGE_KEYS.EXPERIMENTS);
		if (!experimentsJson) return false;

		let experiments = JSON.parse(experimentsJson);
		for (const key in experiments) {
			let experiment = experiments[key];
			if (experiment.id === fromArmId) {
				experiments[key]['id'] = toArmId;
				localStorage.setItem(LOCAL_STORAGE_KEYS.EXPERIMENTS, JSON.stringify(experiments));
				return true;
			}
		}
		return false;
	} catch (error) {
		Sentry.captureException(error, {
			extra: {
				action: 'Error: Failed To Check Experiment Arm',
				toArmId
			}
		});

		if (force) forceArmFallback(toArmId);

		return false;
	}
};

/**
 * This option will only be run in the case of a failed experiment arm change
 * Forces the experiment arm to a specified ID by setting a fallback experiment configuration in localStorage.
 * This method is used to ensure an experiment arm is active even if the original configuration fails to load.
 * @param {string} toArmId - The ID of the experiment arm to force.
 */
export const forceArmFallback = async (toArmId) => {
	try {
		// LOG ERROR
		const FALLBACK_EXPERIMENT_ID = '999999';
		let experiments = {
			[FALLBACK_EXPERIMENT_ID]: {
				id: toArmId,
				condition_expression: 'true'
			}
		};
		localStorage.setItem(LOCAL_STORAGE_KEYS.EXPERIMENTS, JSON.stringify(experiments));
	} catch (error) {
		Sentry.captureException(error, {
			extra: {
				action: 'Error: Failed To Force Experiment Arm',
				toArmId
			}
		});
	}
};
